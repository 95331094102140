<template>
  <div class="content content--center">
    <section class="preloader">
      <div class="preloader__text"> {{ t('message.please_wait') }}</div>
      <preloader></preloader>
    </section>
  </div>
</template>

<script>
import {useI18n} from "vue-i18n";
import Preloader from "@/components/Preloader";

export default {
  name: 'page-loader',
  components: {Preloader},
  setup() {
    const i18n = useI18n();

    return {t: i18n.t}
  }
}
</script>

<style lang="scss">
.preloader {
  text-align: center;

  &__text {
    margin-bottom: 24px;
    font-size: 32px;
    font-weight: 500;
    line-height: 1.13;
    color: rgba(0, 0, 0, 0.87);
  }
}
</style>
