<template>
  <template v-if="!loading">
    <header-line></header-line>
    <div class="content" v-bind:class="{'header_wo_logo': data.hideHeaderLogo}">
      <div class="site-width">
        <div class="confirm__page">
          <checkout-header-line step="3"></checkout-header-line>

          <div class="checkout__confirm">
            <div class="confirm__notice">
              <div class="notice__icon">
                <svg class="icon i24x24"><use xlink:href="#i-checked"></use></svg>
              </div>
              <div class="notice__text">
                <p>{{ t('label.thank_you') }}</p>
                <p>{{ t('label.purchase_success') }}</p>
              </div>
            </div>

            <template v-for="(leg, key) in reservation.legs" v-bind:key="leg.id">
              <div class="confirm__card card card--info" v-bind:class="{'mt-5': key > 0}">
                <figure class="card__image">
                  <img v-if="getVoucher(leg.id).image_1x" v-bind:src="getVoucher(leg.id).image_1x.src"
                       v-bind:alt="getVoucher(leg.id).name"
                       v-bind:srcset="getVoucher(leg.id).image_2x.src +' 2x, ' + getVoucher(leg.id).image_3x.src + ' 3x'"/>
                </figure>
                <div class="card__block">
                  <div class="card__voucher-code" v-if="leg.voucherCode">
                    <strong>{{ t('label.voucher_code') }}:</strong> {{ leg.voucherCode }}
                  </div>
                  <div class="card__title">{{ getVoucher(leg.id).name }}</div>
                  <div class="card__description" v-html="getVoucher(leg.id).description"></div>
                  <div class="card__options" v-if="getVoucher(leg.id).priceType !== 'FIXED' || getVoucher(leg.id).multipleOptions">
                    <div class="card__label">{{ t('label.option') }}</div>
                    {{ getSelectOptions(getVoucher(leg.id), data.currencySymbol)[leg.variant] || leg.variant }}
                  </div>
                  <div class="card__price">
                    <div class="card__label">{{ t('label.total_price') }}</div>
                    {{ numberFormat(leg.grandTotalCents, data.currencySymbol, true) }}
                    <div class="card__label" v-if="leg.isRetail && data.retailTaxPercentage">
                      {{ t('label.tax_including') }}
                      {{ data.retailTaxDescription + ' ' + data.retailTaxPercentage }}%
                    </div>
                  </div>
                  <div class="card__btns">
                    <a class="btns btn-greenbrd" target="_blank"
                       v-if="!leg.isRetail"
                       title="Download Voucher PDF"
                       v-bind:href="'/api/pdf/download?confirmationNumber='
                       + reservationFetchParams.confirmationNumber
                       +'&securityHash='
                       + reservationFetchParams.securityHash
                       + '&voucherCode='
                       + leg.voucherCode
                       + '&lang='
                       + lang"
                    >
                      {{ t('label.view_voucher') }}
                    </a>
                  </div>
                </div>
              </div>
              <div class="card__info">
                <div class="info__column">
                  <div class="card__label">{{ t('label.delivery_to') }}</div>
                  <span v-if="leg.isRetail && reservation.shippingAddress">
                    {{ reservation.shippingAddress.street }} {{ reservation.shippingAddress.house }},
                    {{ reservation.shippingAddress.postcode }} {{ reservation.shippingAddress.city }},
                    {{ reservation.shippingAddress.country }}
                    <br>
                  </span>
                  <span v-else>{{ leg.email }}</span>
                </div>
                <div class="info__column">
                  <div class="card__label">{{ t('label.recipient') }}</div>
                  {{ leg.recipientFirstName }} {{ leg.recipientLastName }}
                </div>
                <div class="info__column" v-if="!leg.isRetail">
                  <div class="card__label">{{ t('label.voucher_pin') }}</div>
                  {{ leg.voucherPin }}
                </div>
                <div class="info__btns">
                  <a class="info__link btns btn-white" target="_blank"
                     title="Download Receipt PDF"
                     v-bind:href="'/api/pdf/receipt?confirmationNumber=' + reservationFetchParams.confirmationNumber
                       +'&securityHash=' + reservationFetchParams.securityHash + '&lang=' + lang"
                  >{{ t('label.view_receipt') }}</a>
                </div>
                <div class="info__bg" v-if="!leg.isRetail">
                </div>
              </div>
            </template>

            <div class="confirm__btns">
              <router-link v-bind:to="{name: 'shop'}" class="btns btn-orange" tag="button">
                {{ t('button.continue_shopping') }}
              </router-link>
            </div>
          </div>
        </div>
      </div>
    </div>
  </template>
  <page-loader v-else></page-loader>
</template>

<script>
  import { useRoute, useRouter } from "vue-router";
  import { useStore } from "vuex";
  import { computed, onMounted, ref, watchEffect } from 'vue'
  import HeaderLine from "@/components/HeaderLine";
  import CheckoutHeaderLine from "@/components/CheckautHeaderLine";
  import { useI18n } from "vue-i18n";
  import PageLoader from "@/components/PageLoader";
  import { ACTION_TYPES } from "@/constants";
  import { getSelectOptions } from "@/helpers/VoucherVariantsHelper";
  import { numberFormat } from "@/helpers/PriceHelper";

  export default {
    name: 'confirmation',
    components: { PageLoader, CheckoutHeaderLine, HeaderLine },
    setup() {
      const router = useRouter();
      const store = useStore();
      const route = useRoute();
      const reservationFetchParams = computed(() => store.getters.reservationFetchParams);
      if (!reservationFetchParams.value.confirmationNumber || !reservationFetchParams.value.securityHash) {
        router.push({ name: 'home', params: route.params });
      }

      const loading = ref(false);

      const reservation = computed(() => store.getters.reservation);
      const reservationLoading = computed(() => store.getters.reservationLoading);
      const reservationLoaded = computed(() => store.getters.reservationLoaded);
      const data = computed(() => store.getters.data);
      onMounted(() => {
        store.dispatch(ACTION_TYPES.clearCart);
        if (!reservation.value && !reservationLoading.value && !reservationLoaded.value) {
          store.dispatch(ACTION_TYPES.loadReservation);
        } else if (
          reservationLoaded.value && reservation.value
          && !reservationLoading.value
          && reservation.value.confirmationNumber !== reservationFetchParams.value.confirmationNumber
        ) {
          store.dispatch(ACTION_TYPES.loadReservation);
        }
        store.dispatch(ACTION_TYPES.clearCart);
      });

      watchEffect(() => {
        loading.value = !data.value || !reservation.value;
        if (!reservation.value && !reservationLoading.value && reservationLoaded.value) {
          router.push({ name: 'home', params: route.params })
        }
      })
      const mappedVouchers = ref({});
      const vouchers = computed(() => store.getters.vouchers);
      const getVoucher = (voucherId) => {
        if (mappedVouchers.value[voucherId]) {
          return mappedVouchers.value[voucherId];
        }
        if (!vouchers.value) {
          return null;
        }
        mappedVouchers.value[voucherId] = vouchers.value.find(voucher => (voucher.id === voucherId));

        return mappedVouchers.value[voucherId];
      };
      const i18n = useI18n();

      const x3pdf = require('../img/i-pdf@3x.png');
      const x2pdf = require('../img/i-pdf@2x.png');
      const x1pdf = require('../img/i-pdf.png');

      return {
        t: i18n.t,
        data,
        loading,
        reservation,
        getVoucher,
        lang: route.params.lang,
        x3pdf, x2pdf, x1pdf,
        reservationFetchParams,
        numberFormat,
        getSelectOptions
      }
    }
  }
</script>

<style>
  .mt-5 {
    margin-top: 50px !important;
  }
</style>